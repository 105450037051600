<template>
  <div class="login-container">
    <div class="login">
      <div class="c-333333 rem44">登录</div>
      <div class="c-8E929B rem30 mt10">请输入您的登录密码</div>
      <div>
        <van-form @submit="onSubmit">
          <van-field
            v-model="username"
            class="username"
            :left-icon="usernamePng"
            placeholder="用户名"
          />
          <van-field
            v-model="password"
            :left-icon="passwordPng"
            type="password"
            placeholder="密码"
            class="mt20"
          />
          <div class="flex just-between mt12 rem26 c-999999">
            <p @click="toPhoneLogin">短信验证码登录</p>
            <p>忘记密码？</p>
          </div>
          <div class="mt40">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              :disabled="!username && !password"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
      <div class="text-center c-8E929B rem34 mt16" @click="toRegister">新用户注册</div>
      <div class="flex align-center mt20">
        <img
          :src="notChoosePng"
          alt=""
          @click="agreeFn(true)"
          v-if="!agreement"
          class="mr4 img12"
        />
        <img :src="choosedPng" alt="" @click="agreeFn(false)" v-else class="mr4 img12" />
        <span class="readed rem26">阅读并同意<span class="c-4D7BFE">《平台服务协议》</span></span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Form, Field, Button, Radio, RadioGroup } from 'vant'

Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Radio)
Vue.use(RadioGroup)
export default {
  data() {
    return {
      username: '',
      password: '',
      usernamePng: require('@/assets/images/login/username.png'),
      passwordPng: require('@/assets/images/login/password.png'),
      notChoosePng: require('@/assets/images/index/notChoose.png'),
      choosedPng: require('@/assets/images/index/choosed.png'),
      agreement: false,
    }
  },
  created() {},
  computed: {},
  methods: {
    onSubmit() {},
    // 手机登录
    toPhoneLogin() {
      this.$router.push({
        path: `/phoneLogin?appId=${this.$store.state.common.appId}`,
      })
    },
    // 注册
    toRegister() {
      this.$router.push({
        path: '/register',
      })
    },
    agreeFn(flag) {
      this.agreement = flag
    },
  },
}
</script>
<style lang="less" scoped>
.login-container {
  height: 100vh;
  background-color: #f5f6fa;
  padding: 0 0.52rem 0 0.48rem;
  .login {
    padding-top: 1.52rem;
    .username {
      margin-top: 0.68rem;
    }
  }
}
/deep/ .van-field__value {
  display: inherit;
}
/deep/ .van-field__value:before {
  content: '|';
  display: inline-block;
  height: 0.28rem;
  width: 1px;
  color: #4d7bfe;
  margin-right: 0.24rem;
}
/deep/ .van-field__left-icon {
  margin-right: 0.14rem;
}
.van-cell {
  box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.12rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
}
.van-button--normal {
  font-size: 0.34rem;
}
/deep/ .van-field__left-icon .van-icon {
  font-size: 0.48rem;
}
</style>
